





































/* eslint-disable class-methods-use-this */
/* eslint-disable lines-between-class-members */

import { UserDetails } from '@/types';
import {
  Vue, Component, Watch,
} from 'vue-property-decorator';

@Component
export default class AdoptionUserDetailsDialog extends Vue {
  dialog = false
  loading = false

  get value(): boolean {
    return this.$store.getters.adoptionUserDetailsDialog;
  }

  get dialogInitialUser(): UserDetails | null{
    return this.$store.getters.dialogInitialUser;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    console.log(`${this.constructor.name}: onValueChanged() val =`, val); // eslint-disable-line no-console

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    console.log(`${this.constructor.name}: onDialogChanged() val =`, val); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'adoptionUserDetails');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog non scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  get fullName(): string {
    return `${this.dialogInitialUser?.firstName} ${this.dialogInitialUser?.lastName}`;
  }

  get phone(): string | null {
    return this.dialogInitialUser?.phone ?? null;
  }

  get email(): string | null {
    return this.dialogInitialUser?.email ?? null;
  }

  get address(): string | null {
    return this.dialogInitialUser?.address ?? null;
  }

  onCancel() {
    this.$store.dispatch('setDialogInitialUser', null);

    this.dialog = false;
  }
}
